import { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { Spinner } from '../spinner';
import { Alert } from '../alert';

export const RootLoaderLayout: FC<PropsWithChildren<{ viewStatus: 'pending' | 'error' }>> = ({ children, viewStatus }) => {

  return (
    <div className={styles.layout}>
      <div className={`${styles.iconBackground} ${styles[viewStatus]}`}>
        {viewStatus === 'error' ? <Alert/> : <Spinner/>}
      </div>
      <div className={styles.info}>
        <div className={styles.items}>
          {children}
        </div>
      </div>
    </div>
  );

};
