import React, { Suspense } from 'react';
import './index.scss';
import './style/index.module.scss';
import 'leaflet/dist/leaflet.css';
import { createRoot } from 'react-dom/client';
import { useAtomValue } from 'jotai';
import { rootOrientationEffect } from './hooks/useOrientation';
import { RootLoaderLayout } from './components/rootLoaderLayout';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('root element not found');

const root = createRoot(rootElement);

const LazyApp = React.lazy(() => import('./app'));

const Wrapper = () => {

  useAtomValue(rootOrientationEffect);

  return (
    <Suspense fallback={(
      <RootLoaderLayout viewStatus={'pending'}>
        <div>Loading...</div>
      </RootLoaderLayout>
    )}>
      <LazyApp/>
    </Suspense>
  );

};

// start react app
root.render(<Wrapper/>);
