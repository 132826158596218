import { atom, useAtomValue } from 'jotai';
import { atomEffect } from 'jotai-effect';

export type Orientation = 'portrait' | 'landscape';

// Is screen width greater than height? If so, we're in landscape mode.
const getOrientation = (): Orientation => (window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

const orientationValueAtom = atom(getOrientation());

orientationValueAtom.debugPrivate = true;

const orientationEffect = atomEffect((get, set) => {

  const handler = () => {

    set(orientationValueAtom, getOrientation());

  };

  window.addEventListener('resize', handler);

  return () => window.removeEventListener('resize', handler);

});

orientationEffect.debugPrivate = true;

export const orientationAtom = atom((get) => {

  get(orientationEffect);

  const orientation = get(orientationValueAtom);

  return {
    orientation,
    portrait: orientation === 'portrait',
    landscape: orientation === 'landscape',
  };

});

orientationAtom.debugLabel = 'orientation';

export const rootOrientationEffect = atomEffect((get) => {

  const { orientation } = get(orientationAtom);

  document.body.setAttribute('data-orientation', orientation);

});

export const useOrientation = () => {

  return useAtomValue(orientationAtom);

};
