import styles from './styles.module.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Loading from './loading.svg?react';
import { useAnimationSync } from '../../hooks/useAnimationSync';

export const Spinner = () => {

  return (
    <Loading ref={useAnimationSync(styles.spin)} className={styles.spinner}/>
  );

};
