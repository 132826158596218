import { useLayoutEffect, useRef, MutableRefObject } from 'react';

const isCssAnimation = (input: Animation): input is CSSAnimation => {

  return 'animationName' in input;

};

const getAnim = (target: Element, animationName: string) => {

  return target.getAnimations().find((anim) => isCssAnimation(anim) && anim.animationName === animationName);

};

export const useAnimationSync = <T extends Element>(animationName: string): MutableRefObject<T | null> => {

  const ref = useRef<T>(null);

  useLayoutEffect(() => {

    const target = ref.current;

    if (!target) return;

    const anim = getAnim(target, animationName);

    if (anim) {

      anim.startTime = 0;

    }

  }, [ref]);

  return ref;

};
